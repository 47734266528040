const environment = process.env.NEXT_PUBLIC_ENV || process.env.NODE_ENV;

const Settings = {
    Sentry: {
        enabled: process.env.NEXT_PUBLIC_ENV !== 'local',
        dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
        environment
    }
};

export default Settings;
