// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import { Integrations as TracingIntegrations } from '@sentry/tracing';
import Settings from './config.ts';

const {
  Sentry: {
    dsn,
    environment,
    enabled,
  },
} = Settings;

/* ================================================ */
/*  Por ahora, Sentry está seteando la info         */
/*  de sesión en el archivo src/lib/main.tsx        */
/*  debido a que sino, esa info se pierde           */
/*  cada vez que se recarga la página o se navega   */
/* ================================================ */

Sentry.init({
  dsn,
  environment,
  enabled,
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1.0,
  integrations: [new TracingIntegrations.BrowserTracing()],
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
