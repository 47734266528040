import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { gaInit } from '@components/utils/analytics';
import { AuthProvider } from '@/providers/Auth';
import Head from 'next/head';
import './globalStyles.scss';

const ZenriseApp = ({ Component, pageProps }) => {
    if (typeof window !== 'undefined' && sessionStorage.getItem('GA_INITIALIZED') !== 'true') {
        gaInit(); // Initializing Google Analytics
        sessionStorage.setItem('GA_INITIALIZED', 'true');
    }

    return (
        <>
            <Head>
                <title>Alquileres Online</title>
                <link
                    rel="icon"
                    href="/Simbolo.svg"
                    type="image/svg+xml"
                />
            </Head>
            <AuthProvider>
                <CssBaseline />
                <Component
                    {...pageProps}
                />
            </AuthProvider>
        </>
    );
};

export default ZenriseApp;
